<template>
  <div>
    <!-- <this-header /> -->
    <validation-observer ref="simpleRules">
      <div class="anan-set-nav-conttainer mt-150">
        <div class="row">
          <div class="col-md-2 col-lg-2 col-sm-12">
            <side-bar class="d-none d-md-block" />
          </div>

          <div class="col-md-10 col-lg-10 col-sm-12">
            <div class="card position-relative">
              <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
                <div class="anan-tabs__nav" />
              </div>

              <div class="d-flex justify-content-between align-items-center px-2">
                <div>
                  <h3 class="font-weight-bolder">
                    {{ $t('announcement') }}
                  </h3>
                </div>

                <div class="d-flex align-items-center">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="search_val"
                      type="text"
                      :placeholder="$t('search')"
                      class="anan-input__input"
                      @keyup.enter="getData()"
                    >
                    <div class="anan-input__suffix">
                      <button
                        type="button"
                        class="anan-button anan-button--normal anan-button--primary"
                        @click="getData()"
                      >
                        <i class="fal fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <b-overlay
                :show="showOver"
                variant="transparent"
                opacity="0.99"
                blur="5px"
                rounded="sm"
              >
                <template #overlay>
                  <div class="text-center">
                    <p id="cancel-label">
                      กรุณารอสักครู่...
                    </p>
                  </div>
                </template>

                <b-row class="match-height justify-content-center">
                  <b-col
                    v-for="(data, index) in items"
                    :key="index"
                    cols="12"
                    class="px-5"
                  >

                    <b-card
                      class="cursor-pointer p-2"
                      style="box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) !important;"
                    >
                      <div style="position: absolute;bottom: 10px; left: 25px;font-size: 10px;color: gray;">
                        {{ time(data.created_at) }}
                      </div>
                      <div class="d-flex justify-content-between align-items-center">
                        <div>

                          <img
                            v-if="data.file_file"
                            :src="GetImg('Notice_file', data.file_file)"
                            :img-alt="data.file_file"
                            width="70px"
                            height="70px"
                          >
                          <img
                            v-else
                            src="@/assets/images/logo/logo-p2.webp"
                            width="80px"
                          >
                        </div>
                        <div class="mr-auto ml-3 col-6 text-truncate">
                          <h4 class="text-primary ">
                            {{ data.postName }}
                          </h4>

                          <div
                            style="font-size: 10px !important;"
                            v-html="truncateContent(data.content)"
                          />
                        </div>
                        <div style="position: absolute;bottom: 10px; right: 15px;">

                          <b-button
                            size="sm"
                            variant="gradient-primary"
                            style="padding: 4px 10px; font-size: 12px;"
                            @click="Viewitem(data)"
                          >
                            อ่านต่อ <feather-icon
                              icon="ChevronsRightIcon"
                              class="p-0"
                            />
                          </b-button>
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                </b-row>

                <div class="demo-spacing-0 d-flex justify-content-end mb-2">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                    @input="getData()"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCardText, BCard, BCol, BRow, BOverlay, BPagination, BButton,
} from 'bootstrap-vue'
import {
  // ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import moment from 'moment-timezone'
import SideBar from './profile/component/SideBar.vue'

export default {
  name: 'LineNotify',
  components: {
    SideBar,
    // ThisHeader,
    BCardText,
    BCard,
    BCol,
    BRow,
    ValidationObserver,
    BOverlay,
    BPagination,
    BButton,
  },
  data() {
    return {
      isActive: 'all',
      search_val: null,
      items: [
        // {
        //   title: 'หัวข้อ',
        //   content: 'ข้อมูล',
        //   timeAdd: '10-11-66',
        //   img: require('@/assets/images/anan-img/empty/empty.png'),
        // },
        // {
        //   title: 'หัวข้อ',
        //   content: 'ข้อมูล',
        //   timeAdd: '10-11-66',
        //   img: require('@/assets/images/anan-img/empty/empty.png'),
        // },
        // {
        //   title: 'หัวข้อ',
        //   content: 'ข้อมูล',
        //   timeAdd: '10-11-66',
        //   img: require('@/assets/images/anan-img/empty/empty.png'),
        // },
        // {
        //   title: 'หัวข้อ',
        //   content: 'ข้อมูล',
        //   timeAdd: '10-11-66',
        //   img: require('@/assets/images/anan-img/empty/empty.png'),
        // },
        // {
        //   title: 'หัวข้อ',
        //   content: 'ข้อมูล',
        //   timeAdd: '10-11-66',
        //   img: require('@/assets/images/anan-img/empty/empty.png'),
        // },
        // {
        //   title: 'หัวข้อ',
        //   content: 'ข้อมูล',
        //   timeAdd: '10-11-66',
        //   img: require('@/assets/images/anan-img/empty/empty.png'),
        // },
        // {
        //   title: 'หัวข้อ',
        //   content: 'ข้อมูล',
        //   timeAdd: '10-11-66',
        //   img: require('@/assets/images/anan-img/empty/empty.png'),
        // },
        // {
        //   title: 'หัวข้อ',
        //   content: 'ข้อมูล',
        //   timeAdd: '10-11-66',
        //   img: require('@/assets/images/anan-img/empty/empty.png'),
        // },
        // {
        //   title: 'หัวข้อ',
        //   content: 'ข้อมูล',
        //   timeAdd: '10-11-66',
        //   img: require('@/assets/images/anan-img/empty/empty.png'),
        // },
      ],
      showOver: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    async getData() {
      this.showOver = true
      try {
        const params = {
          search: this.search_val,
          perPage: this.perPage,
          currentPage: this.currentPage,
        }
        const { data: res } = await this.$http.get('/Notice/getDataNoticeCus', { params })
        res.data.map(ele => {
          // ele.file_file = null
          ele.content = null
        })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
        this.ShowIMG()
      } catch (err) {
        console.log(err)
        this.SwalError(err)
        this.showOver = false
      }
    },
    ShowIMG() {
      const Id = this.items.map(ele => ele._id)
      const params = {
        id: Id,
      }
      this.$http.get('/Notice/GetDataimg', { params })
        .then(response => {
          // console.log(response)
          response.data.forEach((data, index) => {
            // this.items[index].file_file = data.file_file
            this.items[index].content = data.content
          })
        })
        .catch(err => console.log(err))

      // Notice/GetDataimg
    },
    Viewitem(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'notice-view', params: { id: data._id } })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    truncateContent(content) {
      const words = content.split('')
      const truncated = words.slice(0, 100).join('')
      return truncated + (words.length > 100 ? '....' : '')
    },
  },
}
</script>

<style lang="scss" scoped>
.card-notice:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}
</style>
